import axios from 'axios';
import Cookies from 'js-cookie'
import router from "../router/index";
const $http = axios.create({
  baseURL: "",
  timeout: 100000
});

// 添加请求拦截器
$http.interceptors.request.use(
  function(config) {
    // 在发送请求之前,如果cookie为空，可以跳登录页面的
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    //console.log(Cookies.get('name')+" cookide ");
    if(Cookies.get('name')==""){
      router.push("/");
      return false;
    }
    if (Cookies.get('name')) {
      config.headers['Authorization'] = 'Bearer ' + Cookies.get('name') 
    }
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
$http.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么
    return response.data;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default $http
