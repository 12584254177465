<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <span>等级名称:</span>
        <el-input
            v-model="option.vipName"
            clearable
            placeholder="请输入等级名称"
        ></el-input>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
        >查 询</el-button>
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">新增会员等级</el-button>
        <el-button @click="gotolink" type="primary">配置会员简介</el-button>
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"></el-table-column> <!--隐藏列-->
      <el-table-column type="index" :index="indexMethod" width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="vipName" label="等级会员名称"></el-table-column>
      <el-table-column label="等级图片" prop="picture" align="center" width="100px">
        <template v-slot="scope">
          <el-image @click="bigImg(scope.row.picture)" style="width: 50px; height: 50px; margin-bottom:-4px"
            :src=scope.row.picture :preview-src-list="data.srcList">
          </el-image>
          <el-popover placement="top-start" trigger="click"> <!--trigger属性值：hover、click、focus 和 manual-->
            <a :href="scope.row.picture" target="_blank" title="查看最大化图片">
              <img :src="scope.row.picture" style="width: 300px;height: 300px">
            </a>
            <img :src="scope.row.picture" style="width: 50px;height: 50px; cursor:pointer">
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column prop="creationTime" label="创建时间" :formatter="formatCreationTime"></el-table-column>
      <el-table-column fixed="right" label="操作" width="300"> <!--操作固定列 -->
        <template #default="scope">
          <el-button size="small" icon="el-icon-edit" @click="UpdateClick(scope.row)" type="primary">修改</el-button>
          <!-- 根据条件判断是否显示配置权益按钮 -->
          <el-button v-if="scope.row.vipName !== '注册用户' && scope.row.vipName !== '粉丝用户'" size="small" @click="ViewDescImg(scope.row)" type="primary">配置权益</el-button>
          <!--@click="UpdateClick(scope.row)" -->
          <el-popconfirm title="您确定要删除吗？" confirm-button-text='是' cancel-button-text='否' iconColor="red"
            @confirm="deleteClick(scope.row)">
            <template #reference>
              <el-button size="small" icon="el-icon-delete" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination class="fenye" @current-change="handleCurrentChange" :current-page="option.page" :page-size="8"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;" next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;" background
      layout="total, prev, pager, next, jumper" :total="data.total">
    </el-pagination>

    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="52%" title="会员等级配置" v-model="ruleForm.dialogFormVisible" :show-close=true>
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="会员名称" required >
            <el-form-item prop="vipName">
              <el-input v-model="ruleForm.vipName" clearable  placeholder="请输入会员名称" autocomplete="off" style="width: 200px;"></el-input>
            </el-form-item>
        </el-form-item>
          <el-form-item label="排序"  required prop="sort" >
              <el-input v-model="ruleForm.sort" type="number" placeholder="数字（从小到大）" style="width: 200px;" ></el-input>
            </el-form-item>

        <el-upload ref="myupload" action="" :file-list="ruleForm.fileList" :on-preview="handlePreview"
          :on-remove="handleRemove" :beforeUpload="beforeUpload" :http-request="handleUpload" :multiple="false" :limit=1
          style="margin-left: 100px" accept=".png,.jpg">
          <el-button size="small" type="primary">上传图片</el-button>
        </el-upload>
        <el-col>
          <p style="color: red;padding-left: 0px ;padding-bottom: 20px">* 图片格式 宽：660px；高：335px；大小不超过 2MB；扩展名：".png,.jpg,.jpeg" </p>
        </el-col>

        <div v-if="ruleForm.imageDiv">
          <el-image :src="ruleForm.picture" :fit="fit"></el-image>
          <el-button @click="imageClose" style="position:absolute" type="danger" icon="el-icon-close" circle></el-button>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog width="56%" title="等级权益配置" v-model="ruleForm2.dialogFormVisible2" :show-close=true>
      <el-form :model="ruleForm2" :rules="Managerules" ref="ruleFormRef2" label-width="auto" class="demo-ruleForm">
          <el-form-item style="margin-left: 20px" label="选择对应权益:" required prop="VipEquityId">
            <el-select v-model="ruleForm2.VipEquityId" multiple placeholder="请选择权益" ref="vipEquitySelect" style="width: 300px;">
              <el-option v-for="item in data.EquityListData" :key="item.id" :label="item.equity" :value="item.id" />
            </el-select>
          </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('submitForm2')">取 消</el-button>
          <el-button type="primary" @click="submitForm2('submitForm2')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive, ref, onMounted } from "vue";
import { getvipmanage, getequity, addVipManage, addVipManagEquity, deleVipManage, updateVipManage, VipManagegyId } from "../http/vipmanage";
import { FullTimeFormat } from "../utils/common";
import { ElMessage } from "element-plus";
import { putToOSS, getFileNameUUID, getFileExtensions, } from '@/http/oss'
import { useRouter } from "vue-router";
export default {
  methods: {
    formatCreationTime(row, column) {
      const dateTime = new Date(row.creationTime);
      return dateTime.toLocaleString();
    },
  },
  name: "VipManage",
  setup() {
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    const ruleFormRef2 = ref(null);
    const router = useRouter();
    let gotolink = () => {
      router.push({ name: "vipblurbtext" })

    };
    onMounted(async () => { // 在onMounted生命周期钩子中调用GetAllListAsync方法

    });
    //删除事件
    let deleteClick = async (obj) => {
      let data = { "id": obj.id };
      console.log(data.id, "data")
      let delResult = await deleVipManage(data);
      queryManageListFun(option);
      if (delResult) {
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      } else {
        ElMessage.error({
          message: "删除失败！",
          duration: 2000
        });
      }
    };
    //删除上传的图片
    let imageClose = () => {
      ruleForm.picture = "";
      ruleForm.imageDiv = false;
    }
    let ruleForm2 = reactive({
      dialogFormVisible2: false,
      id: "",
      vipName: "",
      picture: "",
      addOrUpdate: 0, //0新增，1更新
    });
    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      vipName: "",
      picture: "",
      imageDiv: false,
      fileList: [],
      addOrUpdate: 0, //0新增，1更新
    });
    //点击新增按钮
    let AddClick = async () => {
      ruleForm.vipName = "";
      ruleForm.picture = "";
      ruleForm.imageUrl = "";
      ruleForm.imageDiv = false;
      ruleForm.fileList = [];
      ruleForm.dialogFormVisible = true; //显示出表单
    };
    let ViewDescImg = async (obj) => {

      ruleForm2.addOrUpdate = 1;
      ruleForm2.id = obj.id;
      let VipManage = await VipManagegyId({ id: obj.id });
      if (VipManage.vipEquityIds) {
        ruleForm2.VipEquityId = VipManage.vipEquityIds.split(',');
      }else{
        ruleForm2.VipEquityId = "";
      }
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      ruleForm2.dialogFormVisible2 = true; //显示出表单
      // let banner = await getById({ id: obj.id });

    };
    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate = 1;
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let VipManage = await VipManagegyId({ id: obj.id });
      ruleForm.picture = VipManage.picture;
      ruleForm.vipName = VipManage.vipName;
      ruleForm.sort = VipManage.sort;
      ruleForm.dialogFormVisible = true; //显示出表单
    };
    //修改的表单验证
    let Managerules = reactive({
      VipEquityId: [{ required: true, message: '请选择权益', trigger: 'change' }]
    });
    //修改的表单验证
    let rules = reactive({
      vipName:[{ required: true, message: "请输入等级会员名称" }],
      sort: [{ required: true, message: "请输入排序" }]
    });

    //提交表单,修改学生信息
    let submitForm = async (formName) => {
      //console.log(formName);
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          console.log("下面的不执行了");
          return false;
        }
      });
      if(!ruleForm.imageDiv){
        ElMessage.warning({
            message: "请上传图片！",
            duration: 2000
          });
       return;
      }
      if (con) {
        return false;
      }
      if (ruleForm.addOrUpdate == 1) { //更新
        let data = {
          "id": ruleForm.id,
          "vipName": ruleForm.vipName,
          "picture": ruleForm.picture,
          "sort": ruleForm.sort,
        }
        await updateVipManage(data);
      } else {//新增
        let data = {
          "vipName": ruleForm.vipName,
          "picture": ruleForm.picture,
          "sort": ruleForm.sort,
        }
        console.log("data", data)
        await addVipManage(data);
        ruleForm.imageUrl = '';
        ruleForm.imageDiv = false;
        ruleForm.fileList = []
      }
      queryManageListFun(option);
      getequityListDataFun({});
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };

    let submitForm2 = async (formName) => {
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef2.value.validate((valid) => {
        if (!valid) {
          con = true;
          console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }
      if (ruleForm2.addOrUpdate == 1) {

        // let VipManageId = ruleForm2.VipEquityId
        // let ManageIds = Object.values(VipManageId);
        // let VipEquityIds = ManageIds.join(',');
        let data = {
          "Id": ruleForm2.id,
          "VipEquityIds": ruleForm2.VipEquityId.join(','),
        }
        let addManage = await addVipManagEquity(data);
        if (addManage) {
          ElMessage.success({
            message: "成功！",
            duration: 2000,
            type: "success",
          });
        } else {
          ElMessage.error({
            message: "失败！",
            duration: 2000
          });
        }
      }
      ruleFormRef2.value.resetFields();
      queryManageListFun(option);
      getequityListDataFun({});
      ruleForm2.dialogFormVisible2 = false;
    };

    //表单重置
    let resetForm = (formName) => {
      ruleForm.imageDiv = false;
      ruleForm.fileList = [];
      ruleForm.dialogFormVisible = false;
      ruleForm2.dialogFormVisible2 = false;

    };
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      EquityListData: [],
      srcList: [""]
    });
    //声明查询条件
    let option = reactive({
      page: 1,
      pageSize: 8,
    });

    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    }

    let queryManageListFun = async (obj) => {
      let arr = await getvipmanage(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await queryManageListFun(option);
    };
    let getequityListDataFun = async (obj) => {
      let equityList = await getequity(obj);
      data.EquityListData = equityList;
    };
    //初始化时，获取初始化数据
    queryManageListFun(option);
    getequityListDataFun({});
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      //接口是接收的guid,这样写不报错
      if (option.cityId == "") {
        option.cityId = null;
      }
      if (option.shopId == "") {
        option.shopId = null;
      }
      option.page = index;
      queryManageListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选

    //大图
    let bigImg = (url) => {
      data.srcList[0] = url;
    };
    let beforeUpload = (file) => {
      // 限制上传类型
      const fileExtensions = getFileExtensions(file.name) === '.jpg' || getFileExtensions(file.name) === '.png' || getFileExtensions(file.name) === '.jpeg'
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000
        });
      }
      return fileExtensions && max2M;
    };
    let handleUpload = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name)
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then(res => {
        //console.log(res,'res')
        // 上传成功之后，转换真实的地址
        //signatureUrl(`flieName/${objName}`).then(res => {
        //console.log(res)
        ruleForm.picture = res.url;
        ruleForm.imageDiv = true;
        //})
      })
    };
    let submitUpload = () => {
      this.$refs.upload.submit();
    };
    let handleRemove = (file, fileList) => {
      console.log(file, fileList);
    };
    let handlePreview = (file) => {
      console.log(file);
    };

    return {
      data,
      handleCurrentChange,
      option,
      UpdateClick,
      ruleForm,
      ruleForm2,
      ruleFormRef,
      ruleFormRef2,
      rules,
      getTabList,
      gotolink,
      Managerules,
      submitForm,
      submitForm2,
      resetForm,
      AddClick,
      deleteClick,
      imageClose,
      getFileExtensions,
      beforeUpload,
      handleUpload,
      submitUpload,
      handleRemove,
      handlePreview,
      bigImg,
      indexMethod,
      ViewDescImg
    };
  }
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.el-image-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0) !important;
}

.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;

  >div {
    width: 15%;
    display: flex;
    align-items: center;

    span {
      width: 50px;
    }

    margin-right: 25px;
  }
}

.fenye {
  margin-top: 20px;
}
</style>
