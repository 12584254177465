<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/VipManage' }">会员等级配置</el-breadcrumb-item>
    <el-breadcrumb-item></el-breadcrumb-item>
  </el-breadcrumb>
  <br>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">新增等级规则</el-button>
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column> <!--隐藏列-->
      <el-table-column
          type="index" :index="indexMethod"
          width="50" style="text-align: center;">
      </el-table-column>
      <el-table-column prop="title" label="会员等级标题"> </el-table-column>
      <el-table-column prop="content" label="会员等级内容" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="sort" label="排序" > </el-table-column>
      <el-table-column fixed="right" label="操作" width="300"> <!--操作固定列 -->
        <template #default="scope">
          <el-button size="small" icon="el-icon-edit" @click="UpdateClick(scope.row)" type="primary">修改</el-button>  <!--@click="UpdateClick(scope.row)" -->
          <el-popconfirm title="您确定要删除吗？"    confirm-button-text='是'
                         cancel-button-text='否' iconColor="red" @confirm="deleteClick(scope.row)">
            <template #reference>
              <el-button size="small" icon="el-icon-delete"  type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
        class="fenye"
        @current-change="handleCurrentChange"
        :current-page="option.page"
        :page-size="8"
        prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
        next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
        background
        layout="total, prev, pager, next, jumper"
        :total="data.total"
    >
    </el-pagination>

    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog
        width="52%"
        title="会员简介信息"
        v-model="ruleForm.dialogFormVisible"
        :show-close=true
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleFormRef"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item label="标题"  required>
          <el-col :span="11">
            <el-form-item prop="title">
              <el-input v-model="ruleForm.title" clearable  placeholder="请输入标题" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="4"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-col>
          <el-col :span="11">
            <el-form-item label="排序"  required prop="sort" >
              <el-input v-model="ruleForm.sort" type="number" placeholder="数字（从小到大）" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item label="等级内容"  required >
            <el-form-item prop="content">
              <el-input
                  id="inp"
                  v-model="ruleForm.content"
                  :rows="2"
                  type="textarea"
                  placeholder="会员等级内容信息"
              >
              </el-input>
            </el-form-item>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive,ref} from "vue";
import {
  getBlurbList, addVipBlurb, getBlurbById, updateVipBlurb,deletBlurbId
} from "../http/vipblurb";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import {FullTimeFormat} from "@/utils/common";
export default {
  name: "VipBlurb",
  setup() {
    const ruleFormRef = ref(null);//表单不能用this.$,取一个重名的
    const router = useRouter();

    //删除事件
    let deleteClick = async (obj) => {
      let data = {"id": obj.id};
      let delResult = await deletBlurbId(data);
      queryBannerListFun(option);
      if(delResult){
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      }else{
        ElMessage.error({
          message: "删除失败！",
          duration: 2000
        });
      }
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      title:"",
      content:"",
      sort:"",
      addOrUpdate:0, //0新增，1更新
    });

    //点击新增按钮
    let AddClick = async () => {
      ruleForm.addOrUpdate=0;
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleForm.id="";
      ruleForm.title="";
      ruleForm.content="";
      ruleForm.sort="";
    };

    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate=1;
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let vipblub = await getBlurbById({ id: obj.id });
      ruleForm.title=vipblub.title;
      ruleForm.content=vipblub.content;
      ruleForm.sort=vipblub.sort;
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //修改的表单验证
    let rules = reactive({
      content: [
        { required: true, message: "请输入标题" }
      ],
      sort: [
        { required: true, message: "请输入排序" }
      ],
      title: [{ required: true, message: "请输入内容" }],
    });

    //提交表单,修改学生信息
    let submitForm = async (formName) => {
      //console.log(formName);
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }
      if(ruleForm.addOrUpdate==1){ //更新
        let data={
          "id":ruleForm.id,
          "Title": ruleForm.title,
          "sort": ruleForm.sort,
          "Content": ruleForm.content
        }
        await updateVipBlurb(data);
      }else {//新增
        let data={
          "Title": ruleForm.title,
          "sort": ruleForm.sort,
          "Content": ruleForm.content
        }
        await addVipBlurb(data);
      }
      queryBannerListFun(option);
      ruleFormRef.value.resetFields();
      //setTimeout(function () {
      ruleForm.dialogFormVisible = false;
      //}, 500);
    };

    //表单重置
    let resetForm = (formName) => {
      //console.log(formName);
      //console.log( ruleFormRef.value);
      ///////ruleFormRef.value.resetFields();

      //setTimeout(function () {
      ruleForm.dialogFormVisible = false;
      //}, 500);
    };
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      cityListData:[],
      srcList:[""]
    });

    //声明查询条件
    let option = reactive({
      cityId: "",
      shopId: "",
      iscity:true,
      isshop:false,
      page: 1,
      pageSize: 8,
    });


    let indexMethod =(index) => {
      return index+(option.page-1)*option.pageSize+1;
    }

    let queryBannerListFun = async (obj) => {
      let arr = await getBlurbList(obj);
      data.total = arr.totalCount;
      data.table = arr;
    };
    //获取城市列表方法

    //初始化时，获取初始化数据
    queryBannerListFun(option);

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      //接口是接收的guid,这样写不报错
      if (option.cityId == "") {
        option.cityId = null;
      }
      if (option.shopId == "") {
        option.shopId = null;
      }
      option.page = index;
      queryBannerListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选


    let submitUpload=()=>{
      this.$refs.upload.submit();
    };
    let handleRemove=(file, fileList)=> {
      console.log(file, fileList);
    };
    let handlePreview=(file)=>{
      console.log(file);
    };

    return {
      data,
      handleCurrentChange,
      option,
      UpdateClick,
      ruleForm,
      ruleFormRef,
      rules,
      submitForm,
      resetForm,
      AddClick,
      deleteClick,
      submitUpload,
      handleRemove,
      handlePreview,
      indexMethod
    };
  }
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.el-image-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color:rgba(255, 255, 255, 0)!important;
}
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 50px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
