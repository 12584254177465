import $http from "./index";
import {PLATFROM_CONFIG} from '../../public/config'
let base = PLATFROM_CONFIG.baseURL;
export const getBlurbList = () => {
    return $http.get(
        `${base}/app/info/vipblurb/back/getvipblrub`,
        {
            timeout: 1000,
        }
    );
};
export const getBlurbById = (data) => {
    return $http.get(
        `${base}/app/info/vipblurb/back/detail`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
export const deletBlurbId = (data) => {
    return $http.get(
        `${base}/app/info/vipblurb/back/delete`,
        {
            params: data,
            timeout: 1000,
        }
    );
};
export const addVipBlurb = (data) => {
    return $http.post(
        `${base}/app/info/vipblurb/back/new`,
        data
    );
}
export const updateVipBlurb = (data) => {
    return $http.post(
        `${base}/app/info/vipblurb/back/update`,
        data
    );
}
